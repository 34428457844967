import styled from "@emotion/styled"
import { AnchorColors } from "./index"

export const StyledAnchor = styled.a<{ colors: AnchorColors }>(
  ({ colors }) => `
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    text-decoration: underline;
    color: ${colors.link};
  
    &:hover {
      color: ${colors.hover};
      text-decoration: none;
    }
  
    &:active { 
      color: ${colors.active};
      text-decoration: none;
    }
  `
)

export const ContentWrapper = styled.span<{ hasIcon: boolean }>(
  ({ hasIcon }) => `
    margin-right: ${hasIcon ? "6px" : "0"};
  `
)

export const IconWrapper = styled.div({
  flexShrink: 0,
})
