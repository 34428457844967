import { BodyMedium, Description } from "@app/base-ui/Typography"
import Chevron from "@app/components/Icon/Chevron"
import { useTheme } from "@emotion/react"
import React, { Fragment } from "react"
import ChevronRight from "../../components/Icon/ChevronRight"
import { IconWrapper, StyledAnchor } from "./styled"

type Color = "dark" | "light"
type Size = "small" | "regular" | "inherit"

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  iconRight?: boolean
  iconLeft?: boolean
  variant?: Color
  size?: Size
}

export type AnchorColors = {
  link: string
  hover: string
  active: string
}

export const TextLink: React.FC<Props> = React.forwardRef<HTMLAnchorElement, Props>((props, ref) => {
  const { iconRight = false, variant = "dark", size = "regular", iconLeft = false, children, ...rest } = props
  const theme = useTheme()

  const mapSizeToText = (size: Size) => {
    switch (size) {
      case "regular":
        return BodyMedium
      case "small":
        return Description
      case "inherit":
        return Fragment
    }
  }
  const Text = mapSizeToText(size)

  const colorsMap: Record<Color, AnchorColors> = {
    dark: {
      link: theme.colors.brandedTextLink,
      hover: theme.colors.grey400,
      active: theme.colors.grey500,
    },
    light: {
      link: theme.colors.grey0,
      hover: theme.colors.grey50,
      active: theme.colors.grey200,
    },
  }

  return (
    <StyledAnchor {...rest} ref={ref} colors={colorsMap[variant]}>
      {iconLeft && (
        <IconWrapper style={{ marginRight: 8 }}>
          <Chevron size={8} />
        </IconWrapper>
      )}
      <Text color="brandedTextLink">{props.children}</Text>
      {iconRight && (
        <IconWrapper style={{ marginLeft: 8 }}>
          <ChevronRight size={8} />
        </IconWrapper>
      )}
    </StyledAnchor>
  )
})

TextLink.displayName = "TextLink"
