function ChevronRight({ size = 8 }: { size?: number }) {
  return (
    <svg css={{ fill: "currentColor" }} width={size} height={size} viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.62018 0.666656L6 3.99999L2.62018 7.33332L2 6.72167L4.7593 3.99999L2 1.27831L2.62018 0.666656Z"
      />
    </svg>
  )
}

export default ChevronRight
