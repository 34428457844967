const itemKey = "isAppRedirect"

export const storeIsAppRedirect = (isAppRedirect: boolean) => {
  if (sessionStorage.getItem(itemKey) !== null) {
    // it means we went back to the /1/1 page
    return
  }
  sessionStorage.setItem(itemKey, isAppRedirect ? "1" : "0")
}

export const isAppRedirect = () => {
  // default to false in SSR
  if (typeof window === "undefined") {
    return false
  }
  return sessionStorage.getItem(itemKey) === "1"
}

export const returnToApp = ({ phoneNumber }: { phoneNumber: string }) => {
  window.location.assign(`${process.env.NEXT_PUBLIC_CASAVO_VISITS_URL_SCHEME}login/${phoneNumber}`)
}
