import { styled } from "../Theme"

export const MainSection = styled.div(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  flex: 1,
  backgroundColor: theme.colors.grey0,
  maxWidth: "100%",
}))

export const ContentWrapper = styled.div<{ footerHeight: number }>(({ theme, footerHeight }) => ({
  paddingBottom: `${theme.spacings["sp-4"] + footerHeight}px`,
  paddingTop: `${theme.spacings["sp-4"]}px`,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  flex: 1,
}))

export const FooterContainer = styled.div({
  zIndex: 50,
  position: "fixed",
  left: 0,
  right: 0,
  bottom: 0,
})
