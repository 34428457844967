const itemKey = "isInAppWebView"

export const storeIsInAppWebView = (isInAppWebView: boolean) => {
  if (sessionStorage.getItem(itemKey) !== null) {
    // it means we went back to the /1/1 page
    return
  }
  sessionStorage.setItem(itemKey, isInAppWebView ? "1" : "0")
}

export const isInAppWebView = () => {
  // default to false in SSR
  if (typeof window === "undefined") {
    return false
  }
  return sessionStorage.getItem(itemKey) === "1"
}

export const closeWebView = ({ phoneNumber }: { phoneNumber: string }) => {
  ;(window as any).ReactNativeWebView.postMessage(JSON.stringify({ type: "phone_confirmed", phoneNumber }))
}
