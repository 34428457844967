import config from "../../i18n.json"

import { pathToRegExp } from "./urlPath"

export function getCurrentLanguage(pathname: string) {
  const parsedPath = pathToRegExp(pathname)

  if (!parsedPath || parsedPath?.groups?.language === undefined) {
    return config.defaultLocale
  }

  return config.locales.includes(parsedPath.groups.language) ? parsedPath.groups.language : config.defaultLocale
}

export function getDefaultLanguage(): string {
  return config.defaultLocale
}
