import { PropertyAddress } from "@app/types/fairMarketValue"
import { useRouter } from "next/router"
import React, { useCallback } from "react"
import { PropertyAddress as PropertyAddressComponent } from "./PropertyAddress"

type AddressSubheaderProps = {
  address: PropertyAddress
  canEditAddress?: boolean
}

export const AddressHeader: React.FC<AddressSubheaderProps> = ({ address, canEditAddress }) => {
  // const [globalAddress] = useAddress()
  const router = useRouter()

  const onEditAddressClick = useCallback(() => {
    router.push(`/${router.query.slug}/edit-address`)
  }, [])

  return (
    <PropertyAddressComponent
      address={address}
      canEditAddress={canEditAddress}
      onEditAddressClick={onEditAddressClick}
    />
  )
}
