import { Locale } from "@app/types/locale"

type TFunction = (key: TranslationKey, values?: Record<string, string>) => string
export type AddressShort = { street: string; number: string }

export const formatAddress = ({
  t,
  address,
}: {
  t: TFunction
  address: { street: string; number: string; city: string }
}) => t("common:address_format", { streetName: address.street, streetNumber: address.number, city: address.city })

/**
 * formats the address with just street name and number, without the city
 */
export const formatAddressShort = ({ lang, address: { street, number } }: { lang: Locale; address: AddressShort }) => {
  switch (lang) {
    case "fr":
      return `${number} ${street}`
    case "en":
    case "it":
      return `${street} ${number}`
  }
}
