import { ReactNode } from "react"

export const HeaderContainer: React.FC<{ className?: string; children: ReactNode }> = ({ children, className }) => {
  return (
    <div
      data-testid="header"
      css={(theme) => ({
        width: "100%",
        backgroundColor: theme.colors.grey0,
        alignItems: "center",
        display: "flex",
        flexShrink: 0,
        height: 60,
        justifyContent: "center",
        position: "sticky",
        top: 0,
        [theme.mqs.tablet]: {
          height: 80,
        },
        zIndex: 200,
      })}
    >
      {/* to center and set maxWidth */}
      <div
        css={{
          width: "100%",
          alignItems: "center",
          maxWidth: 1280,
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: 16,
          paddingRight: 16,
        }}
        className={className}
      >
        {children}
      </div>
    </div>
  )
}
