import { AddressHeader, LogoHeader } from "@app/components/Headers"
import { useElementSize } from "@app/hooks/useElementSize"
import { PropertyAddress } from "@app/types/fairMarketValue"
import { CSSProperties, ReactNode, useRef } from "react"
import { SlotPlaceholder } from "../Slot"
import { ContentWrapper, FooterContainer, MainSection } from "./styled"

type Props = {
  header?: React.ReactElement
  subHeader?: React.ReactElement
  footer?: React.ReactElement
  style?: CSSProperties
  address?: PropertyAddress
  canEditAddress?: boolean
  children: ReactNode
  withAddress?: boolean
}

const FALLBACK_FOOTER_SIZE = {
  height: 80,
  width: 0,
}

export const Layout: React.FC<Props> = ({
  footer,
  header,
  subHeader,
  children,
  address,
  canEditAddress = false,
  withAddress = true,
  ...props
}) => {
  const footerRef = useRef<HTMLDivElement>(null)
  const { height: footerHeight } = useElementSize(footerRef, FALLBACK_FOOTER_SIZE)
  const footerExtraSpace = footer ? footerHeight : 0
  return (
    <MainSection>
      <SlotPlaceholder slot={header}>
        <LogoHeader />
      </SlotPlaceholder>
      {withAddress && address && <AddressHeader address={address} canEditAddress={canEditAddress} />}
      {subHeader && subHeader}
      <ContentWrapper {...props} footerHeight={footerExtraSpace}>
        {children}
      </ContentWrapper>
      <FooterContainer ref={footerRef}>{footer && footer}</FooterContainer>
    </MainSection>
  )
}
