function Chevron({ size = 8 }: { size?: number }) {
  return (
    <svg css={{ fill: "currentColor" }} height={size} viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M5.29289 0.146484L1.43934 4.00004L5.29289 7.85359L6 7.14648L2.85395 4.00004L6 0.853591L5.29289 0.146484Z"
      />
    </svg>
  )
}

export default Chevron
