import { Description } from "@app/base-ui/Typography"
import { HouseNew } from "@app/components/Icon/HouseNew"
import { TextLink } from "@app/design-lib/TextLink"
import { formatAddress } from "@app/lib/formatAddress"
import { Address } from "@app/types/address"
import useTranslation from "next-translate/useTranslation"
import { AddressContainer } from "../../Offer/ListingPricesSuggestions/styled"

type PropertyAddressProps = {
  address: Pick<Address, "city" | "number" | "street">
  canEditAddress?: boolean
  onEditAddressClick?: VoidFunction
}

export const PropertyAddress: React.FC<PropertyAddressProps> = ({
  address,
  canEditAddress = false,
  onEditAddressClick,
}) => {
  const { t } = useTranslation()

  const hasFullAddress = Boolean(address.street && address.number && address.city)
  if (!hasFullAddress) return null

  return (
    <AddressContainer>
      <HouseNew />
      <Description
        data-testid="property-address"
        color="grey600"
        css={(theme) => ({
          marginLeft: theme.spacings["sp-1"],
          paddingLeft: theme.spacings["sp-1"],
          ...(canEditAddress ? { paddingRight: theme.spacings["sp-1"] } : {}),
          borderLeft: `1px solid ${theme.colors["grey200"]}`,
        })}
      >
        {formatAddress({ t, address })}
      </Description>
      {canEditAddress && (
        <TextLink
          css={(theme) => ({
            color: theme.colors.brandedTextLink,
          })}
          onClick={onEditAddressClick}
        >
          {t("survey:editAddress_button")}
        </TextLink>
      )}
    </AddressContainer>
  )
}
