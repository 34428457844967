import styled from "@emotion/styled"

export const SectionContainer = styled.div(({ theme }) => ({
  paddingTop: theme.spacings["sp-4"],
  width: "100%",
}))

export const AddressContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.colors.grey50,
  padding: `${theme.spacings["sp-1"]}px ${theme.spacings["sp-2"]}px`,
  width: "100%",
  position: "sticky",
  top: 60,
  [theme.mqs.tablet]: {
    top: 80,
  },
}))

export const ListingPricesRangeContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  marginTop: theme.spacings["sp-3"],
  width: "100%",
}))

export const PriceContainer = styled.div(({ theme }) => ({
  width: "50%",
  padding: `${theme.spacings["sp-1.5"]}px ${theme.spacings["sp-1"]}px`,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}))

export const WidgetPrimaryLink = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  cursor: "pointer",
}))

export const Greybox = styled.div(({ theme }) => ({
  borderRadius: 4,
  backgroundColor: theme.colors.grey200,
  background: `linear-gradient(110deg, ${theme.colors.grey200} 8%, ${theme.colors.grey50} 18%, ${theme.colors.grey200} 33%)`,
  backgroundSize: "400% 100%",
  animation: "3.4s shineSkeleton linear infinite",
}))
