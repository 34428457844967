import { CompanyHeaderContent } from "@app/components/Headers/LogoHeader/CompanyHeaderContent"
import { TenantHeaderContent } from "@app/components/Headers/LogoHeader/TenantHeaderContent"
import { isCasavo } from "@app/lib/tenants/isCasavo"
import React from "react"
import { HeaderContainer } from "./HeaderContainer"

export const LogoHeader: React.FC = () => {
  const isCompanyHeader = isCasavo()

  return <HeaderContainer>{isCompanyHeader ? <CompanyHeaderContent /> : <TenantHeaderContent />}</HeaderContainer>
}
