import { isUnicredit } from "@app/lib/tenants/isUnicredit"

export const HouseNew = () => {
  if (isUnicredit()) {
    return (
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.04592 0L0.75 4.125V9.39487C0.75 10.8337 1.92068 12 3.36502 12H4.87072V8.625C4.87072 8.211 5.20763 7.875 5.62357 7.875H6.37643C6.792 7.875 7.12928 8.211 7.12928 8.625V12H8.63497C10.0793 12 11.25 10.8337 11.25 9.39487V4.125L6.04592 0Z"
          fill="#262626"
        />
      </svg>
    )
  }
  return (
    <svg
      css={{ fill: "currentColor" }}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_33172_44909)">
        <path d="M12.6668 6.7V3.16667H10.6668V4.9L8.00016 2.5L1.3335 8.5H3.3335V13.8333H7.3335V9.83333H8.66683V13.8333H12.6668V8.5H14.6668L12.6668 6.7ZM11.3335 12.5H10.0002V8.5H6.00016V12.5H4.66683V7.29333L8.00016 4.29333L11.3335 7.29333V12.5Z" />
        <path d="M6.66699 7.16668H9.33366C9.33366 6.43334 8.73366 5.83334 8.00033 5.83334C7.26699 5.83334 6.66699 6.43334 6.66699 7.16668Z" />
      </g>
      <defs>
        <clipPath id="clip0_33172_44909">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
