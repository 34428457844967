import { mq } from "@app/design-lib/theme"
import { isAppRedirect } from "@app/lib/casavoVisitsUtils/appRedirectUtils"
import { isInAppWebView } from "@app/lib/casavoVisitsUtils/appWebViewUtils"
import { getDefaultLanguage } from "@app/lib/language"
import useTranslation from "next-translate/useTranslation"
import { useRouter } from "next/router"
import React from "react"

export const CompanyHeaderContent: React.FC = () => {
  const { lang } = useTranslation()
  const router = useRouter()

  const isSoftLanding = router.asPath?.includes("/1/4")
  const onLogoClick = () => {
    if (isInAppWebView() || isAppRedirect()) {
      return router.push("/")
    }
    if (isSoftLanding) {
      return (window.location.href =
        lang === getDefaultLanguage()
          ? `${process.env.NEXT_PUBLIC_WEBSITE_URL}/?view=C`
          : `${process.env.NEXT_PUBLIC_WEBSITE_URL}/${lang}/?view=C`)
    }
    return (window.location.href =
      lang === getDefaultLanguage()
        ? `${process.env.NEXT_PUBLIC_WEBSITE_URL}/`
        : `${process.env.NEXT_PUBLIC_WEBSITE_URL}/${lang}/`)
  }

  return (
    <div css={{ margin: "0 auto" }}>
      <a href="#" onClick={onLogoClick} style={{ display: "flex", alignContent: "center" }}>
        <img
          alt=""
          aria-hidden="true"
          css={{ width: "110px", [mq.large]: { width: "auto" } }}
          src="/flow/icons/casavo-logo.svg"
        />
      </a>
    </div>
  )
}
