import { RefObject, useCallback, useEffect, useState } from "react"

export type ElementSize = {
  width: number
  height: number
}

export const useElementSize = (ref: RefObject<HTMLElement>, fallbackSize: ElementSize): ElementSize => {
  const [elemSize, setElemSize] = useState<ElementSize>(fallbackSize)

  const handleResize = useCallback(() => {
    if (ref.current) {
      setElemSize({
        height: ref.current.clientHeight,
        width: ref.current.clientWidth,
      })
    }
  }, [ref])

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [ref, handleResize])

  return elemSize
}
